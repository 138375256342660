.login-form-wrapper {
  width: 450px;
  background: #ffffff;
  padding: 32px 40px;
}

.field {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* gray-800 */
  color: #424242;
  text-align: start;
  margin-bottom: 4px;
}

.input {
  height: 42px;
  background: #ffffff;
  border: 1px solid var(--gray-600);
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* gray-800 */
  color: #424242;
  padding: 10px;
  width: 448px;
}

.input input {
  background-color: rgba(0, 0, 0, 0) !important;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* gray-800 */
  color: #424242;
}

.error-icon svg {
  width: 21px;
  height: 21px;
  color: #e14c4c;
}

.error-msg {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #e14c4c;
}

.remember-me > span:nth-child(2) {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* gray-9 */
  color: #262626;
}

.forgot-password,
.signup {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* blue-7 */
  color: #096dd9;
}

.forgot-password:hover,
.signup:hover {
  cursor: pointer;
}

.login-btn,
.login-btn:focus {
  height: 48px;

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;

  /* Certik-Red */
  background: #e5453d;
  border-color: #e5453d;
  border-radius: 4px;

  margin-top: 24px;
}

.login-btn:hover {
  color: #ffffff;
  background: #e5453d;
  border-color: #e5453d;
  opacity: 0.7;
}

.or-row {
  margin-top: 24px;
}

.or-row hr {
  border: 0;
  height: 1px;
  background: #d1d5db;
}

.or-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #6b7280;
}

.telegram-wrapper {
  margin-top: 24px;
}

.telegram-wrapper > div {
  text-align: center;
}

@media (max-width: 575px) {
  .login-form-wrapper {
    width: 100%;
  }
}

.checkbox {
  font-weight: 500;
  /* gray-800 */
  color: #424242;
}

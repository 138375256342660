.token-input-container {
  display: inline-block;
  text-align: center;
  flex: 1;
}
/* .token-input-container:nth-of-type(3n + 3) + .token-input-container:before {
  content: "\2013";
  display: inline-block;
  text-align: center;
  padding: 0 5px;
  color: #6c757d;
  font-weight: bold;
} */

.visible-icon svg {
  width: 16px;
  height: 16px;
}

.error-icon svg {
  width: 21px;
  height: 21px;
  color: #e14c4c;
}

.token-input-field {
  max-width: 54px;
  height: 32px;
  border-radius: 4px;
  text-align: center;
  padding: 5px;
  font-size: 1rem;
  box-shadow: none !important;
  outline: 0 !important;
  margin: 0 2px;
  border: 1px solid #d9d9d9;
}

.token-input-field:focus {
  border-color: #007bff !important;
}

.verification-token {
  display: flex;
}
